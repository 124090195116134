import { Spin } from 'antd';
import Loadable, { LoadableComponent } from 'react-loadable';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { COOKIES } from '@/utils/enum/storage';

type RouterType = {
    [property: string]: LoadableComponent;
};

interface ComponentWrapType {
    isLogin?: boolean;
    name: string;
}

const Loading = (
    <div className="loading-box">
        <Spin tip="Loading..."></Spin>
    </div>
);

const ConfigRouter: RouterType = {
    // 登陆
    login: Loadable({
        loader: () => import('@/views/login'),
        loading: () => Loading,
    }),
    // 注册
    register: Loadable({
        loader: () => import('@/views/register'),
        loading: () => Loading,
    }),
    // 重置密码
    forget: Loadable({
        loader: () => import('@/views/forget'),
        loading: () => Loading,
    }),
    // 解绑谷歌
    unbind: Loadable({
        loader: () => import('@/views/unbind'),
        loading: () => Loading,
    }),
    // 绑定谷歌
    google: Loadable({
        loader: () => import('@/views/google-bind'),
        loading: () => Loading,
    }),
    // 管理系统
    main: Loadable({
        loader: () => import('@/views/main'),
        loading: () => Loading,
    }),
    routeBody: Loadable({
        loader: () => import('@/views/main/RouterComponent'),
        loading: () => null,
    }),

    /*
     ** 控制面板
     */
    // 控制面板
    home: Loadable({
        loader: () => import('@/views/home-page'),
        loading: () => null,
    }),

    /*
     ** 合伙人管理
     */

    //  合伙人列表
    list: Loadable({
        loader: () => import('@/views/partner-manage/parter-list'),
        loading: () => null,
    }),
    //  合伙人报表
    statement: Loadable({
        loader: () => import('@/views/partner-manage/partner-statement'),
        loading: () => null,
    }),

    /*
     ** 合伙人管理
     */
    userList: Loadable({
        loader: () => import('@/views/user-manage/user-list'),
        loading: () => null,
    }),

    /*
     ** 财务管理
     */
    assets: Loadable({
        loader: () => import('@/views/finance-manger/agent-assets'),
        loading: () => null,
    }),
    transfer: Loadable({
        loader: () => import('@/views/finance-manger/transfer-record'),
        loading: () => null,
    }),

    /*
     ** 空投管理
     */
    airdrop_details: Loadable({
        loader: () => import('@/views/airdrop-manager/airdrop-details'),
        loading: () => null,
    }),
    airdrop_summary: Loadable({
        loader: () => import('@/views/airdrop-manager/airdrop-summary'),
        loading: () => null,
    }),

    /*
     ** 合约管理
     */
    position: Loadable({
        loader: () => import('@/views/contract-manager/position'),
        loading: () => null,
    }),
    settlement: Loadable({
        loader: () => import('@/views/contract-manager/settlement'),
        loading: () => null,
    }),
};

// 路由跳转拦截处理
export default function ComponentWrap({ isLogin, name }: ComponentWrapType) {
    const Component: any = ConfigRouter[name];
    const token = Cookies.get(COOKIES.TOKEN);
    // 判断是否需要登陆
    if (isLogin && !token) {
        return <Navigate to="/login" />;
    }
    return <Component />;
}
