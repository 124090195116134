import qs from 'qs';
import md5 from 'js-md5';
import Cookies from 'js-cookie';
import { JSEncrypt } from 'js-encrypt';
import dayjs from 'dayjs';
// import sha256 from 'js-sha256';
import sha256 from 'crypto-js/sha256';
// import CryptoJS from 'crypto-js';
import BigNumber from 'bignumber.js';
import zh_CN from 'antd/es/date-picker/locale/zh_CN';
import zh_TW from 'antd/es/date-picker/locale/zh_TW';
import en_US from 'antd/es/date-picker/locale/en_US';
import id_ID from 'antd/es/date-picker/locale/id_ID';
import ko_KR from 'antd/es/date-picker/locale/ko_KR';
import vi_VN from 'antd/es/date-picker/locale/vi_VN';

/**
 * 数据签名
 * @param data 接口数据
 * @returns {string} hash
 */
const handleSign = (data: any) => {
    let empty = '';
    const arr = Object.keys(data);
    arr.sort().forEach((key) => {
        if (data[key] instanceof Array) {
            if (key !== arr[arr.length - 1]) {
                empty += `${key}=${handleArr(data[key])}&`;
            } else {
                empty += `${key}=${handleArr(data[key])}`;
            }
        } else if (data[key] instanceof Object) {
            empty += `${key}=${handleSign(data[key])}`;
        } else {
            if (key !== arr[arr.length - 1]) {
                empty += `${key}=${data[key]}&`;
            } else {
                empty += `${key}=${data[key]}`;
            }
        }
    });
    return empty;
};

const handleArr = (arr: any[]) => {
    let empty = '';
    arr.forEach((item, index) => {
        if (arr.length - 1 === index) {
            if (typeof item === 'string' || typeof item === 'number') {
                empty += `${item}`;
            } else {
                empty += `${handleSign(item)}`;
            }
        } else {
            if (typeof item === 'string' || typeof item === 'number') {
                empty += `${item},`;
            } else {
                empty += `${handleSign(item)},`;
            }
        }
    });
    return empty;
};

export const getSign = (data: any) => {
    let empty = '';
    Object.keys(data)
        .sort()
        .forEach((key) => {
            if (data[key] instanceof Array) {
                empty += `${key}[${data[key]}]`;
            } else {
                empty += `${key}${data[key]}`;
            }
        });
    return md5(`${empty}jiaoyisuo@2017`);
};

export const getPostParams = (obj: any) => {
    return qs.stringify(obj);
};

// RSA非对称加密
// export const getPass = (data: string) => {
//     const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
//     MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCxrrfUf3VOtQ0AMU3E0EYo+LPp
//     c8awS/yTKroLDKPA9tpmwjorryzPXhrUIA6NIuIJNKTHjsshIMrhNtm+fM6TO543
//     9m8wtCWX2AEwSXTWrUF6PUgvUS9oqliXqLreeUeajC2C0C3a67GkHu+hF60+Lf7A
//     8gG8pPB4ksFzFr5cIwIDAQAB
//     -----END PUBLIC KEY-----`;
//     const encrypt = new JSEncrypt();
//     encrypt.setPublicKey(PUBLIC_KEY);
//     return encrypt.encrypt(data);
// };

export const getPass = (data: string) => {
    // let CryptoJS = require('crypto-js');
    const salt = '6jBafRANsu89avX4eGvnR9m7y9XNJZx28gnexangentmanager';
    return sha256(data + salt).toString();
    // return CryptoJS.HmacSHA256(data + salt).toString();
};

/**
 * 邮箱省略
 * @param value 邮箱
 * @returns {string}
 */
export const resetEmail = (value: string) => {
    if (value) {
        return value.replace(/(.{2}).+(@.+)/g, '$1****$2');
    }
};

/**
 * 操作localstorage
 * @param {string} type 传递 set, get, remove
 * @param {string} key
 * @param value 值
 */
export const localStorage = (type: string, key: string, value?: any) => {
    const str = JSON.stringify(value);
    if (type === 'set') {
        window.localStorage.setItem(key, str);
    }
    if (type === 'get') {
        const data = window.localStorage.getItem(key) || '';
        if (data) {
            return JSON.parse(data);
        } else {
            return '';
        }
    }
    if (type === 'remove') {
        window.localStorage.removeItem(key);
    }
};

/**
 * 设置cookie
 * @param {string} key
 * @param {string} value
 * @param {number|string} time 时间为天数
 * 设置其它的时间参考：https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
 */
export const setCookie = (key: string, value: string, time: number) => {
    Cookies.set(key, value, { expires: time });
};

/*****
 * i  为时区值
 * timeVal 传入的时间戳
 *******/
const getLocalTime = (i: number, timeVal: number | string) => {
    //得到1970年一月一日到现在的秒数
    let len = 0;
    if (typeof timeVal !== 'number') {
        len = new Date(timeVal).getTime();
    } else {
        len = timeVal;
    }
    var d = new Date(timeVal);
    //本地时间与GMT时间的时间偏移差
    var offset = d.getTimezoneOffset() * 60000;
    //得到现在的格林尼治时间
    var utcTime = len + offset;
    return utcTime + 3600000 * i;
};

/**
 * 时间转格式化正常时间格式
 * @param {number|string|Date} val 时间，时间戳的话需要《秒》
 * @param {number} num 对应格式类型
 * @returns {string|number} 返回需要的时间格式
 */
export const dateFormat = (val: number | string, num?: number) => {
    let date: any = '';
    if (!val) return '--';
    if (Number(val) < 0) return '--';
    if (typeof val === 'number') {
        date = dayjs(getLocalTime(8, val * 1000));
        // date = dayjs(val * 1000);
    } else {
        // date = dayjs(val);
        date = dayjs(getLocalTime(8, val));
    }

    switch (num) {
        case 1:
            return date.format('YYYY-MM-DD HH:mm:ss');
        case 2:
            return date.format('YYYY-MM-DD HH:mm');
        case 3:
            return date.format('YYYY-MM-DD');
        case 4:
            return date.valueOf(); // 毫秒
        case 5:
            return date.unix(); // 秒
        default:
            return date.format('YYYY-MM-DD HH:mm:ss');
    }
};

/**
 * 组件RangePicker, 转为时间戳
 * @param {Array} val 组件的时间
 * @param {number} num 对应格式类型
 * @returns {Array} 返回需要的时间格式
 */
export const getTime = (val: any, num?: number) => {
    const timeFrom = val ? dateFormat(val[0]['_d'], num || 5) : 0;
    const timeTo = val ? dateFormat(val[1]['_d'], num || 5) : 0;
    return [timeFrom, timeTo];
};

export const copy = (_val: any) => {
    let transfer = document.createElement('input');
    document.body.appendChild(transfer);
    transfer.value = _val; // 这里表示想要复制的内容
    // transfer.focus();
    transfer.select();
    if (document.execCommand('copy')) {
        document.execCommand('copy');
    }
    transfer.blur();
    document.body.removeChild(transfer);
};

/**
 * 保留小数位
 * @param {number|string} val 需要格式的数值
 * @param {number} decimal 保留的位数
 * @returns {string|number} 返回需要数值
 */
export const DecimaltoFixed = (num: any, decimal: number) => {
    if (!num) {
        return 0;
    }
    num = num.toString();
    const index = num.indexOf('.');
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1);
        num = parseFloat(num).toFixed(decimal);
        num = num.replace(/\.?0+$/, '');
    }
    return num;
};

export const BigNum = (num1: any, num2: any, type: number) => {
    switch (type) {
        case 1: // 加法
            return new BigNumber(num1).plus(num2).toNumber();
        case 2: // 减法
            return new BigNumber(num1).minus(num2).toNumber();
        case 3: // 乘法
            return new BigNumber(num1).multipliedBy(num2).toNumber();
        case 4: // 除法
            return new BigNumber(num1).dividedBy(num2).toNumber();
    }
};

// 防抖
export const debounce = (fn: Function, wait: number) => {
    let timeout: any = null;
    return function () {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
    };
};

export const langType = (lang: string) => {
    if (lang === 'zh-CN') {
        return zh_CN;
    } else if (lang === 'zh-TW') {
        return zh_TW;
    } else if (lang === 'ko-KR') {
        return ko_KR;
    } else if (lang === 'id-ID') {
        return id_ID;
    } else if (lang === 'vi-VN') {
        return vi_VN;
    } else {
        return en_US;
    }
};
